<template>
  <div id="descComAndSort" @click.stop>
    <div style="display: flex; padding: 10px; font-size: 14px">
      <div style="flex: 1; text-align: left; color: #aaa">
        <span v-if="title">{{ title }}</span>
        当前统计周期<span v-if="startDate && endDate">
          {{ startDate }} ~ {{ endDate }}
        </span>
        <span v-else> : {{ _startDate }} ~ {{ _endDate }} </span>
      </div>

      <div v-if="isFilter">
        <div
          class="dropdown"
          style="
            font-weight: bold;
            position: absolute;
            display: flex;
            right: 20px;
          "
          @click="sort"
        >
          <div style="color: #515a6e">排序</div>
          <svg
            t="1657850692458"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8637"
            width="20"
            height="20"
          >
            <path
              d="M437 885.4c-9.4 0-18.6-3.7-25.5-10.5L226.7 690.1c-14.1-14.1-14.1-36.9 0-50.9 14.1-14.1 36.9-14.1 50.9 0L401 762.5V178c0-19.9 16.1-36 36-36s36 16.1 36 36v671.4c0 14.6-8.8 27.7-22.2 33.3-4.5 1.8-9.2 2.7-13.8 2.7zM586 560c-19.9 0-36-16.1-36-36V178c0-14.6 8.8-27.7 22.2-33.3 13.5-5.6 29-2.5 39.2 7.9l169 169.5c14 14.1 14 36.9-0.1 50.9s-36.9 14-50.9-0.1L622 265.1V524c0 19.9-16.1 36-36 36zM802 761H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 653H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 869H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
              p-id="8638"
            ></path>
            <path
              d="M586.9 617m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8639"
            ></path>
            <path
              d="M586.9 725m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8640"
            ></path>
            <path
              d="M585.9 833m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8641"
            ></path>
          </svg>
        </div>
        <div
          style="
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div id="sortContainer" class="sortContainer">
            <div
              style="
                height: 40px;
                box-shadow: 0 1px 0 #409ad5 inset, 0 -1px 0 #20638f inset;
              "
              :class="showFilterClass"
              @click="sortData(item)"
              v-for="(item, key) in sortList"
              :key="key"
            >
              {{ item.name }}
              <svg
                t="1657855187286"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="9893"
                width="12"
                height="12"
              >
                <path
                  fill="#fff"
                  d="M279.15323 958.059228l217.110799-363.160177-141.539436 0L354.724593 63.957829l-151.123938 0 0 530.943021L62.057421 594.900849 279.15323 958.059228 279.15323 958.059228zM570.078783 64.464885l386.443791 0 0 108.976114L570.078583 173.440999 570.078783 64.464885 570.078783 64.464885zM570.078783 369.594007 878.364965 369.594007l0-108.974515L570.078783 260.619492 570.078783 369.594007zM570.078783 565.747016l230.128573 0 0-108.976114L570.078783 456.770901 570.078783 565.747016 570.078783 565.747016zM570.078783 761.904621l151.972163 0L722.050945 652.930305l-151.972163 0L570.078783 761.904621zM570.078783 958.059228l73.813355 0 0-108.974315-73.813355 0L570.078783 958.059228z"
                  p-id="9894"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";

export default {
  name: "descCom",
  components: {},
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isFilter: {
      type: Boolean,
      default: false,
    },

    sortList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      // 选择日期
      _startDate: "",
      _endDate: "",

      showFilterClass: "hide",
    };
  },
  methods: {
    sort () {
      // 下拉展开
      if (this.showFilterClass == "show") {
        this.showFilterClass = "hide";
      } else {
        this.showFilterClass = "show";
      }
    },
    sortData (data) {
      this.showFilterClass = "hide";
      this.$emit("sortData", data);
    },
    removeLister () {
      this.showFilterClass = "hide";
    },
  },
  mounted () {
    document.body.addEventListener("click", this.removeLister);
  },
  destroyed () {
    console.log("移除监听");
    document.body.removeEventListener("click", this.removeLister);
  },
  async created () {
    this._startDate =
      this.startDate || moment().subtract(7, "day").format("YYYY-MM-DD");

    this._endDate = this.endDate || moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
/* 排序下拉加载 */
.sortContainer {
  background: #386ba1;
  color: white;
  padding: 0 20px;
  position: absolute;
  right: 8px;
  top: 50px;
  line-height: 44px;
  z-index: 999;
}

.sortContainer .show,
.sortContainer .hide {
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
.sortContainer .show {
  display: block;
  max-height: 9999px;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.2s ease-in-out;
  -moz-animation: showAnimation 0.2s ease-in-out;
  -webkit-animation: showAnimation 0.2s ease-in-out;
  -moz-transition: max-height 0.2s ease-in-out;
  -o-transition: max-height 0.2s ease-in-out;
  -webkit-transition: max-height 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out;
}
.sortContainer .hide {
  max-height: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.1s ease-out;
  -moz-animation: hideAnimation 0.1s ease-out;
  -webkit-animation: hideAnimation 0.1s ease-out;
  -moz-transition: max-height 0.1s ease-out;
  -o-transition: max-height 0.1s ease-out;
  -webkit-transition: max-height 0.1s ease-out;
  transition: max-height 0.1s ease-out;
}

@keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
    -ms-transform: scaleY(0.1);
    -o-transform: scaleY(0.1);
    -webkit-transform: scaleY(0.1);
    transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
    -ms-transform: scaleY(1.04);
    -o-transform: scaleY(1.04);
    -webkit-transform: scaleY(1.04);
    transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  60% {
    -moz-transform: scaleY(0.98);
    -ms-transform: scaleY(0.98);
    -o-transform: scaleY(0.98);
    -webkit-transform: scaleY(0.98);
    transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
    -ms-transform: scaleY(1.02);
    -o-transform: scaleY(1.02);
    -webkit-transform: scaleY(1.02);
    transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
</style>
